var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b10-base',[(_vm.tanomaliasChecklist.length > 0 || !_vm.hasAnomaliaTextoLibre)?_c('ValidationProvider',{attrs:{"name":"idtanomalia_checklist","rules":_vm.formRules.idtanomalia_checklist},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"items":_vm.tanomaliasChecklist,"item-value":"tanomalia_checklist.idtanomalia_checklist","item-text":"tanomalia_checklist.descripcion","label":"Tipo de anomalía","clearable":"","error-messages":errors},on:{"change":function($event){return _vm.anomaliaSelected(_vm.form.idtanomalia_checklist)}},scopedSlots:_vm._u([{key:"item",fn:function(slotProps){return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap"},[_vm._v(" "+_vm._s(slotProps.item.tanomalia_checklist.descripcion)+" ")])],1)]}}],null,true),model:{value:(_vm.form.idtanomalia_checklist),callback:function ($$v) {_vm.$set(_vm.form, "idtanomalia_checklist", $$v)},expression:"form.idtanomalia_checklist"}})]}}],null,false,3549123942)}):_vm._e(),(_vm.hasAnomaliaTextoLibre)?_c('ValidationProvider',{attrs:{"name":"descripcion","rules":_vm.formRules.descripcion},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"clearable":"","flat":"","label":"Descripción","disabled":typeof(_vm.form.idtanomalia_checklist) !== 'undefined' && _vm.form.idtanomalia_checklist !== null,"error-messages":errors},model:{value:(_vm.form.descripcion),callback:function ($$v) {_vm.$set(_vm.form, "descripcion", $$v)},expression:"form.descripcion"}})]}}],null,false,2947195990)}):_vm._e(),(_vm.hasAgregarADefinicionChecklist)?_c('ValidationProvider',{attrs:{"name":"agregaradefinicioncheck","rules":_vm.formRules.agregaradefinicioncheck},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"disabled":(typeof(_vm.form.idtanomalia_checklist) !== 'undefined' && _vm.form.idtanomalia_checklist !== null),"label":"Crear como tipo de anomalía genérica","error-messages":errors},model:{value:(_vm.form.agregaradefinicioncheck),callback:function ($$v) {_vm.$set(_vm.form, "agregaradefinicioncheck", $$v)},expression:"form.agregaradefinicioncheck"}})]}}],null,false,1644826236)}):_vm._e(),(_vm.hasAnomaliaTextoLibre)?_c('b10-textarea',{attrs:{"label":"Descripción ampliada"},model:{value:(_vm.form.descripcion_ampliada),callback:function ($$v) {_vm.$set(_vm.form, "descripcion_ampliada", $$v)},expression:"form.descripcion_ampliada"}}):_vm._e(),_c('b10-textarea',{attrs:{"label":"Notas internas"},model:{value:(_vm.form.notas_internas),callback:function ($$v) {_vm.$set(_vm.form, "notas_internas", $$v)},expression:"form.notas_internas"}}),_c('ValidationProvider',{attrs:{"name":"idgrado_anomalia","rules":_vm.formRules.idgrado_anomalia},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"items":_vm.gradosAnomalia,"item-value":"idgrado_anomalia","item-text":"descripcion","label":"Grado de anomalía","clearable":"","error-messages":errors},model:{value:(_vm.form.idgrado_anomalia),callback:function ($$v) {_vm.$set(_vm.form, "idgrado_anomalia", $$v)},expression:"form.idgrado_anomalia"}})]}}])}),_c('v-checkbox',{attrs:{"label":"Es una mejora"},model:{value:(_vm.form.mejora),callback:function ($$v) {_vm.$set(_vm.form, "mejora", $$v)},expression:"form.mejora"}}),_c('v-checkbox',{attrs:{"label":"Fue subsanada durante la revisión"},model:{value:(_vm.form.subsanado),callback:function ($$v) {_vm.$set(_vm.form, "subsanado", $$v)},expression:"form.subsanado"}}),(_vm.isInsert && _vm.idtfichero && _vm.hasInsertAnomaliaAdjuntoPerm)?_c('ValidationProvider',{attrs:{"name":"files","rules":_vm.formRules.files},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"label":"Ficheros","show-size":"","prepend-icon":"attach_file","chips":"","multiple":"","accept":_vm.stringTypeExtensions,"error-messages":errors},on:{"change":_vm.filesChange},model:{value:(_vm.form.files),callback:function ($$v) {_vm.$set(_vm.form, "files", $$v)},expression:"form.files"}})]}}],null,false,1773880413)}):_vm._e(),(_vm.accionesCorrectoras.length > 0)?[_c('b10-subheader',{attrs:{"label":"Acciones correctoras","icon":_vm.$vuetify.icons.values.accionCorrectora}}),_c('v-list',{staticClass:"ml-n4"},_vm._l((_vm.accionesCorrectoras),function(accion,key){return _c('v-list-item',{key:key},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"break-word"},[_c('strong',[_vm._v("Incluir:")]),_vm._v(" "+_vm._s(accion.title)),_c('strong',[_vm._v(":")])]),_c('v-card-subtitle',{staticClass:"py-1 ml-n4"},[_c('div',{staticClass:"d-flex justify-start"},[_c('v-btn-toggle',{staticClass:"v-size--small",attrs:{"color":"primary","mandatory":"","borderless":"","size":"small"},model:{value:(_vm.form[accion.fieldnameAccionesARealizar]),callback:function ($$v) {_vm.$set(_vm.form, accion.fieldnameAccionesARealizar, $$v)},expression:"form[accion.fieldnameAccionesARealizar]"}},_vm._l((_vm.accionesARealizar.values),function(item){return _c('v-btn',{key:item,staticClass:"v-size--small",attrs:{"value":item,"color":"white","density":"comfortable","size":"small"},on:{"click":function($event){return _vm.clickAccionesARealizar(item, accion)}}},[_vm._v(" "+_vm._s(_vm.accionesARealizar.descripciones[item])+" ")])}),1)],1)]),(_vm.form[accion.fieldnameMensajeFacturacionAccionCorrectora])?_c('v-chip',{attrs:{"color":"info"}},[_vm._v(" "+_vm._s(_vm.form[accion.fieldnameMensajeFacturacionAccionCorrectora])+" ")]):_vm._e(),_c('v-divider',{staticClass:"mt-3"})],1)],1)}),1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }